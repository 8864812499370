<template>
    <!-- <PageHead :image="require('@/assets/images/pages/About-Us.jpg')"/> -->
    <PageHead image="/images/pages/Our-Team.jpg" title="Our Team"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Who We Are" :items="this.store.methods.getSingleMenuItem('About')"/>
            <div class="col-sm-8 col-md-9">
                <!-- <h3 class="page-title">Our Team</h3> -->
                <div class="row">
                    <div v-for="(item,index) in items" :key="index" class="col-md-4 wow fadeInUp" data-wow-delay="500ms">
                        <TeamTile :item="item" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHead from '../utilities/PageHead.vue'
import SidebarTitle from '../SidebarTitle.vue'
import TeamTile from '../utilities/TeamTile.vue'
export default {
    name:'TeamPage',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle,
        TeamTile
    },
    props: {},
    data(){ 
        return{
            items:null
        }
    },
    methods: {},
    computed: {},
    mounted: function(){
        fetch(process.env.VUE_APP_APIURL+"team-list")
            .then(response => response.json())
            .then(data => (this.items = data));
    }
}
</script>

<style scoped>
</style>