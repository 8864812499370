<template>
    <!-- <PageHead :image="require('@/assets/images/pages/management-associate-programme.jpg')"/> -->
    <PageHead image="/images/pages/management-associate-programme.jpg" title="Management Associate Programme"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Careers" :items="this.store.methods.getSingleMenuItem('Careers')"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                <!-- <h3 class="page-title">Management Associate Programme</h3> -->
                <div class="user_content">
                    <div v-if="page_data!=null">
                        <div v-if="(page_data instanceof Object) && page_data.content" v-html="page_data.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Contact Form -->
        <div v-if="false" class="row mt-5 pt-4">
            <SidebarTitle title="Application Form" />
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                <CareerForm form="Management Associate Programme"/>
            </div>
        </div>
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../../utilities/PageHead.vue'
import CareerForm from '../../utilities/CareerForm.vue'
import SidebarTitle from '../../SidebarTitle.vue'
export default {
    name:'MAP',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle,
        CareerForm
    },
    props: {},
    data(){ 
        return{
            
        }
    },
    methods: {},
    computed: {
        page_data(){
            return this.store.methods.getSinglePage('management-associate-programme')
        }
    },
    mounted: function(){
    },
    created: function(){
        
    }
}
</script>

<style scoped>
</style>