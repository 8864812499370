<template>
    <div class="row">
        <div class="col-md-5">
            <img :src="asseturl+data.image" alt="" />
            <div class="text-center mt-3">
                <p class="h6 mb-1"><strong>{{data.name}}</strong></p>
                <div class="mb-0" v-html="data.summary"></div>
            </div>
        </div>
        <div class="col-md-7">
            <div class="modal-small-font" v-html="data.description"></div>
        </div>
    </div>
</template>

<script>
export default {
    name:'PostModal',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
        data: Object
    },
    data(){ 
        return{
            items:null,
            asseturl:process.env.VUE_APP_ASSETURL
        }
    },
    methods: {},
    computed: {},
    mounted: function(){
        console.log(this.data)
    }
}
</script>

<style scoped>
</style>