<template>
    <div class="row">
        <div class="col-md-12">
            <div class="text-center">                
                <img class="mb-3" style="max-width:400px" :src="data.image" alt="" />
                <p class="h4 mb-1"><strong>{{data.name}}</strong></p>
                <ul class="sponsoring-list my-3" v-if="data.sponsors.length">
                    <li v-for="(list_item, i) in data.sponsors" :key="i">
                        <a v-if="list_item.link && list_item.link!=''" :href="list_item.link" target="_blank">{{list_item.name}}</a>
                        <span v-else>{{ list_item.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'SponsoringModal',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
        data: Object
    },
    data(){ 
        return{
            items:null,
            asseturl:process.env.VUE_APP_ASSETURL
        }
    },
    methods: {
        fullYear(dealdate){
            let date=new Date(dealdate);
            return date.getFullYear()
        }
    },
    computed: {
        
    },
    mounted: function(){
        // console.log(this.data)
    }
}
</script>

<style scoped>
</style>