<template>
    <!-- <PageHead :image="require('@/assets/images/pages/ourservices-banner.jpg')"/> -->
    <PageHead image="/images/pages/ourservices-banner.jpg" title="Our Services"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Services" :items="this.store.methods.getSingleMenuItem('Services')"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                <!-- <h3 class="page-title">Our Services</h3> -->
                <div class="user_content" v-if="page_data!=null">
                    <div v-if="(page_data instanceof Object) && page_data.content" v-html="page_data.content"></div>
                </div>
            </div>
        </div>
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../utilities/PageHead.vue'
import SidebarTitle from '../SidebarTitle.vue'
export default {
    name:'MAP',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle
    },
    props: {},
    data(){ 
        return{
            
        }
    },
    methods: {},
    computed: {
        page_data(){
            return this.store.methods.getSinglePage('our-services')
        }
    },
    mounted: function(){
    },
    created: function(){
        
    }
}
</script>

<style scoped>
</style>