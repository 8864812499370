<template>
    <div class="modal fade" tabindex="-1" id="generalPop">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 v-if="this.store.state.generalModal.title!=''" class="modal-title">Modal title</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                    <div v-if="this.store.state.generalModal.component!=null" class="">
                        <component 
                            :is="this.store.state.generalModal.component" 
                            :data="this.store.state.generalModal.prop">
                        </component>
                    </div>
                    <div v-if="this.store.state.generalModal.content!=''">
                        <div v-html="this.store.state.generalModal.content"></div>
                    </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import TeamModal from './TeamModal.vue'
import DealModal from './DealModal.vue'
import PostModal from './PostModal.vue'
import NewsModal from './NewsModal.vue'
import IframeModal from './IframeModal.vue'
import SponsoringModal from './SponsoringModal.vue'
export default {
    name:'GeneralModal',
    inject:[
        'store'
    ],
    components: {
        TeamModal,
        DealModal,
        PostModal,
        SponsoringModal,
        NewsModal,
        IframeModal
    },
    props: {},
    data(){ 
        return{}
    },
    methods: {},
    computed: {},
    mounted: function(){
    }
}
</script>

<style scoped>
</style>