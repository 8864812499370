<template>
    <div class="row">
        <div class="col-md-5">
            <img :src="data.image" alt="" />
            <div class="text-center mt-3">
                <p class="h6 mb-1"><strong>{{data.name}}</strong></p>
                <p class="mb-0">{{data.post}}</p>
            </div>
        </div>
        <div class="col-md-7">
            <div class="modal-small-font" v-html="data.summary"></div>
        </div>
    </div>
</template>

<script>
export default {
    name:'TeamModal',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
        data: Object
    },
    data(){ 
        return{
            items:null
        }
    },
    methods: {},
    computed: {},
    mounted: function(){
        console.log(this.data)
    }
}
</script>

<style scoped>
</style>