<template>
    <div class="row">
        <div class="col-md-12">
            <img :src="data.image" alt="" />
            <div class="text-center mt-3">
                <p class="h4 mb-1"><strong>{{data.name}}</strong></p>
                <p class="h6 mb-1"><strong>{{data.industry}}</strong></p>
                <p class="mb-0">{{fullYear(data.date)}}</p>
                <p class="mb-0" v-html="data.blurb"></p>
            </div>
            <div class="modal-small-font" v-html="data.description"></div>
        </div>
    </div>
</template>

<script>
export default {
    name:'DealModal',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
        data: Object
    },
    data(){ 
        return{
            items:null,
            asseturl:process.env.VUE_APP_ASSETURL
        }
    },
    methods: {
        fullYear(dealdate){
            let date=new Date(dealdate);
            return date.getFullYear()
        }
    },
    computed: {
        
    },
    mounted: function(){
        // console.log(this.data)
    }
}
</script>

<style scoped>
</style>