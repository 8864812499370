<template>
    <!-- <PageHead :image="require('@/assets/images/pages/experienced-professionals.jpg')"/> -->
    <PageHead image="/images/pages/experienced-professionals.jpg" title="Experienced Professionals"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Careers" :items="this.store.methods.getSingleMenuItem('Careers')"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                <!-- <h3 class="page-title">Experienced Professionals</h3> -->
                <div class="user_content">
                    <div v-if="page_data!=null">
                        <div v-if="(page_data instanceof Object) && page_data.content" v-html="page_data.content"></div>
                    </div>
                    <div v-if="false" class="career-posts">
                        <h3 class='mt-5'>Available Positions</h3>
                        <div v-if="this.store.state.careerPosts.length">
                            <div class="tiled-deals row">
                                <div v-for="(post, index) in this.store.state.careerPosts" :key="index" class="col-lg-4 col-md-4 mb-3">
                                    <div class="single-slide single-deal has-button wow fadeInUp" data-wow-delay="500ms">
                                        <div class="slide-container">
                                            <img :src="asseturl+post.image" alt="" />
                                            <div class="slide-content">
                                                <p class="deal-date"><strong>{{post.name}}</strong></p>
                                                <div class="deal-title mb-4">
                                                    <div v-html="post.summary"></div>
                                                </div>
                                                <a @click="updateModal(post)" href="javascript:;" data-bs-toggle="modal" data-bs-target="#generalPop" class="btn btn-blue btn-no-fill circle-border">Learn More <i class="lni lni-chevron-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else><p>There are no positions currently available.</p></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../../utilities/PageHead.vue'
import SidebarTitle from '../../SidebarTitle.vue'
export default {
    name:'EP',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle
    },
    props: {},
    data(){ 
        return{
            asseturl:process.env.VUE_APP_ASSETURL
        }
    },
    methods: {
        updateModal(post){
            this.store.state.generalModal={
                title: '',
                content: '',
                component: 'PostModal',
                prop:post
            }
        }
    },
    computed: {
        page_data(){
            return this.store.methods.getSinglePage('experienced-professionals')
        }
    },
    mounted: function(){
    },
    created: function(){
        this.store.methods.getCareerPost();
    }
}
</script>

<style scoped>
</style>