<template>
    <div v-if="(deals instanceof Object) && deals.length" class="recent-deals wow fadeInUp" data-wow-delay="500ms">
        <div class="container user_content" :class="(title!=null?'py-5':'py-3')">
            <h2 v-if="title" class="h1 text-center text-blue">Recent Deals</h2>
            <div class="slick-slider" :class="'slick-slider-'+unique_id">
                <div v-for="(deal, index) in deals" :key="index" class="single-slide single-deal has-button">
                    <div class="slide-container"><!--@click="updateModal(deal)"-->
                        <a :class="'deal-link-'+unique_id" :data-id="index" href="javascript:;" data-bs-toggle="modal" data-bs-target="#generalPop">
                            <img :src="deal.image" alt="" />
                            <div class="slide-content">
                                <p class="deal-date text-grey"><small>{{deal.industry}}</small></p>
                                <div class="deal-title mb-4">
                                    <p><strong v-html="deal.blurb"></strong></p>
                                </div>
                                <span class="btn btn-blue btn-no-fill no-border">Learn More <i class="lni lni-chevron-right"></i></span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.min.js'
export default {
    name:'RecentDeals',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
        deals: Object,
        title: {
            type: String,
            default:null
        }
    },
    data(){ 
        return{
            unique_id: Math.floor(Math.random() * 1000),
            asseturl:process.env.VUE_APP_ASSETURL
        }
    },
    methods: {
        updateModal(deal){
            // console.log(deal);
            this.store.state.generalModal={
                title: '',
                content: '',
                component: 'DealModal',
                prop:deal
            }
        }
    },
    computed: {},
    mounted: function(){
        this.$nextTick(function () {
            $('.slick-slider-'+this.unique_id).slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 1500,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 450,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        });
        $(this.$el).find('.deal-link-'+this.unique_id).on('click', (e) => {
            const deal_id = $(e.currentTarget).data('id');
            // console.log(deal_id);
            const deal = this.deals[deal_id];
            this.updateModal(deal);
        });
    },
    updated: function(){
    }
}
</script>

<style>

</style>