<template>
    <div class="page-head position-relative">
        <div class="page-head-content" :style="bgImage">
            <div class="pagehead-overlay"></div>
            <div class="pagehead-block position-absolute top-50 start-50 translate-middle ">
                <div class="pagehead-container wow fadeInUp">
                    <div class="pagehead-content">
                        <h2 class="h1">{{ title }}</h2>
                    </div>
                </div>       
            </div>            
        </div>
    </div>
</template>

<script>
export default {
    name:'PageHead',
    components: {},
    props: {
        image:String,
        title:{
            type: String,
            default: null
        }
    },
    data(){ 
        return{}
    },
    methods: {
        
    },
    computed: {
        bgImage(){
            // let image_path=require('@/assets/'+this.image);
            return 'background: url('+this.image+') no-repeat center center / cover';
        }
    },
    mounted(){
        // console.log(this.image)
    }
}
</script>

<style scoped>
.page-head-content { height: 250px; }
.pagehead-block { text-align:center; color: #fff; width:90%; max-width:650px;}
.pagehead-block .pagehead-container { background: rgba(0,0,0,0.2); padding: 30px; }
.pagehead-block .pagehead-content { padding:30px 0; border-top: 1px solid #eee; border-bottom: 1px solid #eee;}
/* .pagehead-overlay { position: absolute; top:0; left:0; width:100%; height:100%; background: rgba(0, 0, 0, 0) linear-gradient(45deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)) repeat scroll 0% 0%;} */
@media only screen and (min-width: 768px) {
    .page-head-content { height: 80vh; background-attachment: fixed !important;}
}
/* .page-head-content::after { content:" "; position: absolute; width: 100%; height: 100%; background:rgba(0,0,0,0.3)} */
</style>