import {reactive} from "vue";

const state=reactive({
    menuitems:null,
    footermenu:null,
    services:null,
    careers:null,
    careerPosts:[],
    pages:null,
    generalModal:{
        title:"",
        content:"",
        component: null,
        prop:null
    }
});

const methods={
    async getmenuitems() {
        if(state.menuitems==null){
            fetch(process.env.VUE_APP_APIURL_TEMP+"data/menuitems.json")
            .then(response => response.json())
            .then(data => (state.menuitems = data));
        }
    },

    getSingleMenuItem(key){
        if(state.menuitems!=null){
            let singleitems= state.menuitems.find(el=> el.url==key);
            return singleitems;
        }
        return {};
    },

    async getfootermenu() {
        if(state.footermenu==null){
            fetch(process.env.VUE_APP_APIURL_TEMP+"data/footermenu.json")
            .then(response => response.json())
            .then(data => (state.footermenu = data));
        }
    },

    async getServices() {
        if(state.services==null){
            fetch(process.env.VUE_APP_APIURL+"service-list")
            .then(response => response.json())
            .then(data => (state.services = data));
        }
    },

    getSingleService(key){
        if(state.services!=null){
            let singleitems= state.services.find(el=> el.slug==key);
            return singleitems;
        }
        return null;
    },

    async getCareers() {
        if(state.careers==null){
            fetch(process.env.VUE_APP_APIURL_TEMP+"data/careers.json")
            .then(response => response.json())
            .then(data => (state.careers = data));
        }
    },

    getSingleCareer(key){
        if(state.careers!=null){
            let singleitems= state.careers.find(el=> el.slug==key);
            return singleitems;
        }
        return {};
    },

    async getCareerPost() {
        if(state.careerPosts.length==0){
            fetch(process.env.VUE_APP_APIURL_TEMP+"data/career-posts.json")
            .then(response => response.json())
            .then(data => (state.careerPosts = data));
        }
    },

    async getPages() {
        if(state.pages==null){
            fetch(process.env.VUE_APP_APIURL+"page-list")
            .then(response => response.json())
            .then(data => (state.pages = data));
        }
    },

    getSinglePage(key){
        if(state.pages!=null){
            let singleitems= state.pages.find(el=> el.slug==key);
            return singleitems;
        }
        return {};
    },
}

methods.getmenuitems();
methods.getfootermenu();
methods.getPages();

export default {
    state, 
    methods
}