<template>
    <header>
        <div class="container d-flex">
            <div class="logo">
                <router-link :to="{ path: '/' }">
                    <img alt="Vue logo" src="@/assets/images/PP-logo-final.png">
                </router-link>                
            </div>
            <div class="nav ms-auto align-self-stretch">
                <ul class="d-block d-lg-none">
                    <li class="icon-item">
                        <a href="https://www.linkedin.com/company/primepartners-corporate-finance" target="_blank">
                            <i class="lni lni-linkedin-original"></i>
                        </a>
                    </li>
                    <li>
                        <a id="mhamberger" href="#mobile-menu"><span class="navbar-toggler-icon"></span></a>
                    </li>
                </ul>                
                <ul class="d-none d-lg-block">
                    <li v-for="(item, index) in this.store.state.menuitems" :key="index">
                        <!-- <a class="" :href="item.url">{{ item.name }}</a>  -->
                        <router-link :to="{ name: item.url}">{{ item.name }}</router-link>
                        <ul v-if="item.child">
                            <li v-for="(sub_item, i) in item.child" :key="i">
                                <router-link v-if="sub_item.param" :to="{ name: sub_item.url, params: { slug: sub_item.param }}">{{ sub_item.name }}</router-link>
                                <router-link v-else :to="{ name: sub_item.url}">{{ sub_item.name }}</router-link>
                                <!-- <a class="" :href="sub_item.url">{{ sub_item.name }}</a> -->
                            </li>
                        </ul>
                    </li>
                    <li class="icon-item">
                        <a href="https://www.linkedin.com/company/primepartners-corporate-finance" target="_blank">
                            <i class="lni lni-linkedin-original"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>   
    </header>    
</template>

<script>
export default {
    name: 'Menu',
    inject:[
        'store'
    ],
    data() {
        return{}
    },
    mounted: function(){
        // this.store.methods.getmenuitems(); 
    },
    updated: function (){
    }
}
</script>

<style scoped>
header { background: #212167; }
header .logo img { padding: 20px 0; width:130px;}
header .nav > ul{ list-style:none; height:100%; }
header .nav > ul li{ list-style:none; display: inline-block; height:100%; position:relative;}
header .nav ul li a { display: inline-block; color: #fff; font-size: 1rem; padding-left:25px; padding-right:25px; padding-top:30px; height: 100%;}
header .nav ul li a:hover { background: #18184b}
header .nav ul li.icon-item a { font-size: 1.2em; padding-top:28px; vertical-align: middle;}
header .nav > ul li > ul { display:none; position:absolute; left:0; top:100%; z-index:999; text-align: center; background: #18184b}
header .nav > ul li:hover > ul { display:inline-block; }
header .nav > ul li:hover > ul li { /* border-top: 1px solid #eee; */ width: 100%;}
header .nav > ul li:hover > ul li a { padding: 15px 8px; color: #fff;}
header .nav > ul li:hover > ul li:hover a { font-weight: bold;}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 25529' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    width: 1.9em;
    height: 1.9em;
}

@media only screen and (max-width: 991px) {
    header .nav ul li.icon-item a { font-size: 1.4em; padding-top:26px; vertical-align: middle;}
}

@media only screen and (min-width: 992px) {
    header { position:fixed; width: 100%; top:0; left:0; z-index:10; }
}
</style>