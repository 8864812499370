<template>
    <!-- <PageHead :image="require('@/assets/images/pages/news-archive.jpg')"/> -->
    <PageHead image="/images/pages/news-archive.jpg" title="News Archives"/>
    <div class="container page-section my-5 py-4">
        <!-- <h2 class="h1 text-center text-blue mb-5">News Archives</h2> -->
        <div v-if="(news instanceof Object) && news.length" class="recent-deals user_content">
            <div class="tiled-deals row">
                <div v-for="(single, index) in news" :key="index" class="col-lg-3 col-md-4 mb-3">
                    <div class="single-slide single-deal has-button wow fadeInUp" data-wow-delay="500ms">
                        <div class="slide-container">
                            <a @click="updateModal(single)" href="javascript:;" data-bs-toggle="modal" data-bs-target="#generalPop">
                                <div class="slide-image" :style="'background: url('+single.image+') no-repeat center center / cover'">
                                </div>
                                <!-- <img :src="asseturl+single.image" alt="" /> -->
                                <div class="slide-content">
                                    <!-- <p class="deal-date text-grey"><small>{{single.industry}}</small></p> -->
                                    <div class="deal-title mb-4">
                                        <p><strong>{{single.title}}</strong></p>
                                    </div>
                                    <span class="btn btn-blue btn-no-fill no-border">Learn More <i class="lni lni-chevron-right"></i></span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <p>Stay connected with us by following our <a href="https://www.linkedin.com/company/primepartners-corporate-finance" target="_blank">LinkedIn</a> page for our latest updates.</p>
        </div>
    </div>    
</template>

<script>
import PageHead from '../utilities/PageHead.vue'
export default {
    name:'NewsPage',
    inject:[
        'store'
    ],
    components: {
        PageHead
    },
    props: {
    },
    data(){ 
        return{
            asseturl:process.env.VUE_APP_ASSETURL,
            news: {}
        }
    },
    methods: {
        updateModal(deal){
            this.store.state.generalModal={
                title: '',
                content: '',
                component: 'NewsModal',
                prop:deal
            }
        }
    },
    computed: {},
    updated: function(){       
    },
    beforeMount: function(){
        fetch(process.env.VUE_APP_APIURL+"news-list")
            .then(response => response.json())
            .then(data => (this.news = data));
    }
}
</script>

<style scoped>
</style>