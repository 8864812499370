<template>
    <!-- <PageHead :image="require('@/assets/images/pages/connect-with-us.jpg')"/> -->
    <PageHead image="/images/pages/connect-with-us.jpg" title="Connect with Us"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Contact Us" />
            <div class="col-sm-8 col-md-9 wow fadeInUp user_content" data-wow-delay="500ms">
                <!-- <h3 class="page-title">Connect With Us</h3> -->
                <div class="row">
                    <div class="col-sm-8">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.816112058209!2d103.85041501419181!3d1.2842487621418197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190e9d8955eb%3A0x21671544a467f45a!2sPrimePartners%20Corporate%20Finance%20Pte.%20Ltd.!5e0!3m2!1sen!2sin!4v1630929841320!5m2!1sen!2sin" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                    <div class="col-sm-4">
                        <div class="address">
                            <p class="text-grey">
                                <strong>
                                16 Collyer Quay<br />
                                #10-00 Income at Raffles<br />
                                Singapore 049318
                                </strong>
                            </p>
                            <p> 
                            Tel: +65 6229 8088<br />
                            Fax: +65 6229 8089
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mt-5">
                    <p>Please <a class="text-decoration-underline" href="mailto:enquiry@ppcf.com.sg?subject=General Enquiries">click here</a> to contact us with any general enquiries.</p>
                    
                </div>
            </div>
        </div>
        <div class="row mt-5 pt-4">
            <SidebarTitle title="Keep In Touch" />
            <div class="col-sm-8 col-md-9 wow fadeInUp user_content" data-wow-delay="500ms">
                <p>Stay in touch with our latest news and updates by following our LinkedIn page.<br />
                    <a class="mt-2 d-inline-block h4" href="https://www.linkedin.com/company/primepartners-corporate-finance" target="_blank" >
                        <i class="lni lni-linkedin-original"></i>
                    </a>
                </p>
            </div>
        </div>
        <!-- Contact Form -->
        <div v-if="false" class="row mt-5 pt-4">
            <SidebarTitle title="Contact Us" />
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                <form class="form" enctype='multipart/form-data' method="post" @submit="contactSubmit">
                    <div class="row">
                        <div class="col-md-4">
                            <input v-model="cf_name" type="text" class="form-control mb-3" name="name" placeholder="Name*" required/>
                        </div>
                        <div class="col-md-4">
                            <input v-model="cf_email" type="email" class="form-control mb-3" name="email" placeholder="Email*" required/>
                        </div>
                        <div class="col-md-4">
                            <input v-model="cf_subject" type="text" class="form-control mb-3" name="subject" placeholder="Subject (Optional)" />
                        </div>
                        <div class="col-md-12">
                            <textarea v-model="cf_message" class="form-control mb-3" name="message" placeholder="Message*" rows="6" required></textarea>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-blue btn-no-fill">Contact us</button>
                    <span class="ms-2">{{form_submit_info}}</span>
                </form>
            </div>
        </div>
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../utilities/PageHead.vue'
import SidebarTitle from '../SidebarTitle.vue'
export default {
    name:'ContactPage',
    components: {
        PageHead,
        SidebarTitle
    },
    props: {},
    data(){ 
        return{
            cf_name:'',
            cf_email:'',
            cf_subject:'',
            cf_message:'',
            form_submit_info:''
        }
    },
    methods: {
        contactSubmit(e){
            this.form_submit_info="Submitting your request";
            e.preventDefault();
            var formData = new FormData(e.target);
            formData.append('form', 'contact');
            fetch(process.env.VUE_APP_SITEURL+"php/sendmail.php",{
                method:'post',
                // headers: new Headers({
                //     'Content-Type': 'application/json',
                //     'Accept': 'application/json',
                // }),
                body: formData
                // JSON.stringify({
                //     form: 'contact',
                //     name: this.cf_name,
                //     email: this.cf_email,
                //     subject: this.cf_subject,
                //     message: this.cf_message
                // })
            })
            .then(response => response.json())
            .then(data => {
                if(data.msg==1){
                    this.form_submit_info="Sent!";
                }
            });
        }
    },
    computed: {}
}
</script>

<style scoped>
</style>