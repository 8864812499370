<template>
    <div class="video-block position-relative">
        <video-background 
            src="/videos/homepage-video.mp4"
            style="max-height: 400px; height: 100vh;"
            overlay="linear-gradient(45deg,rgba(0,0,0,0.25),rgba(0,0,0,0.25))"
        >
            <div class="video-block-content position-absolute top-50 start-50 translate-middle">
                <h2 class="h1" style="font-weight:600">Empowering clients through exceptional advice</h2>
            </div>
        </video-background>
    </div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
export default {
    name:'VideoBlock',
    components: {
        VideoBackground
    },
    data(){ 
        return{}
    }
}
</script>

<style scoped>
.video-block { text-align:center;}
.video-block-content { color: #fff; }
.video-block-content p { /*max-width: 500px;*/ font-size: 1.1rem; margin-top:35px;}
</style>