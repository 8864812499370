<template>
    <div class="grey-bg py-3">
        <div class="container py-5 text-center wow fadeInUp user_content" data-wow-delay="500ms">
            <div v-if="page_data!=null">
                <div v-if="(page_data instanceof Object) && page_data.content" v-html="page_data.content"></div>
            </div>            
        </div>
    </div>
</template>

<script>
export default {
    name:'HomeSummery',
    inject:[
        'store'
    ],
    components: {},
    props: {},
    data(){ 
        return{}
    },
    methods: {},
    computed: {
        page_data(){
            return this.store.methods.getSinglePage('home-summary')
        }
    },
}
</script>

<style scoped>
</style>