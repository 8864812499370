<template>
    <VideoBlock/>
    <HomeSummery/>
    <div v-if="deals.length">
        <RecentDeals :deals="deals" title="Recent Deals" />
    </div>    
</template>

<script>
import VideoBlock from './VideoBlock.vue'
import HomeSummery from './HomeSummery.vue'
import RecentDeals from '../utilities/RecentDeals.vue'
export default {
    name:'HomePage',
    components: {
        VideoBlock,
        HomeSummery,
        RecentDeals,
    },
    props: {},
    data(){ 
        return{
            deals:[]
        }
    },
    methods: {},
    computed: {},    
    beforeMount(){
        fetch(process.env.VUE_APP_APIURL+"deal/home")
            .then(response => response.json())
            .then(data => (this.deals = data));
    }
}
</script>

<style scoped>
</style>