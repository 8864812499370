<template>
    <!-- <PageHead :image="require('@/assets/images/pages/Careers.jpg')"/> -->
    <PageHead image="/images/pages/Careers.jpg" title="Careers"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Careers" :items="this.store.methods.getSingleMenuItem('Careers')"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp user_content" data-wow-delay="500ms">                
                <!-- <h3 class="page-title">Careers</h3> -->
                <div v-if="page_data!=null">
                    <div v-if="(page_data instanceof Object) && page_data.content" v-html="page_data.content"></div>
                </div>
                <div v-if="(this.store.state.careers instanceof Object)" class="recent-deals">
                    <div class="tiled-deals row wow fadeInUp" data-wow-delay="500ms">
                        <div class="col-md-6 col-lg-4 mb-3">
                            <div class="single-slide single-deal has-button">
                                <router-link :to="{ name: 'Career-EP'}">
                                    <div class="slide-container">
                                        <img :src="asseturl+'Experienced Professionals.jpg'" alt="" />
                                        <div class="slide-content">
                                            <div class="deal-title mb-4">
                                                <p><strong>Experienced Professionals</strong></p>
                                            </div>
                                            <span class="btn btn-blue btn-no-fill no-border">Learn More <i class="lni lni-chevron-right"></i></span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 mb-3">
                            <div class="single-slide single-deal has-button">
                                <router-link :to="{ name: 'Career-MAP'}">
                                    <div class="slide-container">
                                        <img :src="asseturl+'MA Programme.jpg'" alt="" />
                                        <div class="slide-content">
                                            <div class="deal-title mb-4">
                                                <p><strong>Management Associate Programme</strong></p>
                                            </div>
                                            <span class="btn btn-blue btn-no-fill no-border">Learn More <i class="lni lni-chevron-right"></i></span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 mb-3">
                            <div class="single-slide single-deal has-button">
                                <router-link :to="{ name: 'Career-Internships'}">
                                    <div class="slide-container">
                                        <img :src="asseturl+'Internship.jpg'" alt="" />
                                        <div class="slide-content">
                                            <div class="deal-title mb-4">
                                                <p><strong>Internships</strong></p>
                                            </div>
                                            <span class="btn btn-blue btn-no-fill no-border">Learn More <i class="lni lni-chevron-right"></i></span>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <!-- <div v-for="(single, index) in this.store.state.careers" :key="index" class="col-md-6 col-lg-4 mb-3">
                            <div class="single-slide single-deal has-button">
                                <div class="slide-container">
                                    <img :src="asseturl+single.image" alt="" />
                                    <div class="slide-content">
                                        <div class="deal-title mb-4">
                                            <p><strong v-html="single.name"></strong></p>
                                        </div>
                                        <router-link v-if="single.slug" :to="{ name: 'Career', params: { slug: single.slug }}" class="btn btn-blue btn-no-fill circle-border">Learn More <i class="lni lni-chevron-right"></i></router-link>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../utilities/PageHead.vue'
import SidebarTitle from '../SidebarTitle.vue'
export default {
    name:'Careers',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle
    },
    props: {},
    data(){ 
        return{
            asseturl:process.env.VUE_APP_ASSETURL
        }
    },
    methods: {},
    computed: {
        page_data(){
            return this.store.methods.getSinglePage('careers')
        }
    },
    mounted: function(){
    },
    created: function(){
        this.store.methods.getCareers();
    }
}
</script>

<style scoped>
</style>