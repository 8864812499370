<template>
    <div class="sidebar col-sm-4 col-md-3 wow fadeInUp" data-wow-delay="500ms">
        <div class="sidebar-separator"></div>
        <h4 class="text-blue pb-2">{{title}}</h4>
        <div v-if="(items instanceof Object) && Object.keys(items).length" class="sidebar-childitems">
            <ul>
                <li class="mb-2">
                    <router-link :to="{ name: items.url}">{{ items.name }}</router-link>
                </li>
                <li class="mb-2" v-for="(sub_item, i) in items.child" :key="i">
                    <router-link v-if="sub_item.param" :to="{ name: sub_item.url, params: { slug: sub_item.param }}">{{ sub_item.name }}</router-link>
                    <router-link v-else :to="{ name: sub_item.url}">{{ sub_item.name }}</router-link>
                    <ul class="indented" v-if="false && sub_item.child">
                        <li v-for="(ss, j) in sub_item.child" :key="j">
                            <router-link v-if="ss.param" :to="{ name: ss.url, params: { slug: ss.param }}">{{ ss.name }}</router-link>
                            <router-link v-else :to="{ name: ss.url}">{{ ss.name }}</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="sb-mobile-title d-block d-sm-none">
                <div class="sidebar-separator"></div>
                <h4 v-if="$route.name==items.url" class="text-blue mb-4">{{ items.name }}</h4>
                <div v-else v-for="(sub_item, key) in items.child" :key="key">
                    <div v-if="sub_item.param">
                        <h4 v-if="$route.params.slug==sub_item.param" class="text-blue mb-4">{{ sub_item.name }}</h4>
                    </div>
                    <div v-else>
                        <h4 v-if="$route.name==sub_item.url" class="text-blue mb-4">{{ sub_item.name }}</h4>
                    </div>                    
                </div>
            </div>
        </div>
        <div v-else>
            <div class="mt-3 mt-sm-0"></div>
        </div>
        <!-- <div v-if="items" class="sidebar-childitems">
            
        </div> -->
    </div>
</template>

<script>
export default {
    name:'Blank Component',
    components: {},
    props: {
        title:String,
        items:{
            type:Object,
            default: function(){
                return {}
            }
        }
    },
    data(){ 
        return{}
    },
    methods: {},
    computed: {},
    mounted: function(){
        // console.log(this.title)
        // console.log(Object.keys(this.items).length)
    }
}
</script>

<style scoped>
.sidebar-childitems li a { color: #999; font-weight: 400; font-size: 1.1em; }
.sidebar-childitems li a:hover { color: #212167; }
.sidebar-childitems li a.router-link-active { color: #212167; }
.sb-mobile-title h4{ padding-top: 20px;}
ul.indented { padding-left: 25px;}
ul.indented li:before { content: "•"; color: #999; font-weight: bold; display: inline-block; width: 1em; margin-left: -1em; }
ul.indented li a { font-size: 0.9em;}
</style>