<template>
    <div class="row">
        <div class="col-md-12">
            <div class="text-center">                
                <img class="mb-3" style="max-width:400px" :src="data.image" alt="" />
                <p class="h4 mb-1"><strong>{{data.title}}</strong></p>
                <p>{{ CustomDate(data.date) }}</p>
                <div v-html="data.description"></div>
                <!-- <a href="https://www.linkedin.com/company/primepartners-corporate-finance" target="_blank" >
                    <h5 class="d-inline"><i class="lni lni-linkedin-original"></i></h5>
                </a> -->
                <a v-if="data.link" :href="data.link" target="_blank" >
                    <h5 class="d-inline"><i class="lni lni-linkedin-original"></i></h5>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'NewsModal',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
        data: Object
    },
    data(){ 
        return{
            items:null,
            asseturl:process.env.VUE_APP_ASSETURL
        }
    },
    methods: {
        CustomDate(dealdate){
            let date=new Date(dealdate);
            return date.toLocaleString('default', { month: 'long', year:'numeric' });
        }
    },
    computed: {
        
    },
    mounted: function(){
        // console.log(this.data)
    }
}
</script>

<style scoped>
</style>