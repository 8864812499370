<template>
    <div class="row">
        <iframe :src="data" width="100%;" height="500" ></iframe>
    </div>
</template>

<script>
export default {
    name:'IframeModal',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
        data: String
    },
    data(){ 
        return{
            items:null,
            asseturl:process.env.VUE_APP_ASSETURL
        }
    },
    methods: {},
    computed: {},
    mounted: function(){
        console.log(this.data)
    }
}
</script>

<style scoped>
</style>