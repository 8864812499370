<template>
    <!-- <PageHead :image="require('@/assets/images/pages/About-Us.jpg')"/> -->
    <PageHead image="/images/pages/About-Us.jpg" title="Track Record"/>
    <div class="container page-section my-5 py-4">
        <!-- <h2 class="h1 text-center text-blue mb-5">Track Record</h2> -->
        <div v-if="(deals instanceof Object) && deals.length" class="recent-deals">
            <div class="tiled-deals row">
                <div v-for="(deal, index) in deals" :key="index" class="col-lg-3 col-md-4">
                    <div class="single-slide single-deal mb-3 wow fadeInUp" data-wow-delay="500ms">
                        <div class="slide-container">
                            <img :src="asseturl+deal.image" alt="" />
                            <div class="slide-content">
                                <p class="deal-date text-grey"><small>{{deal.industry}}</small></p>
                                <div class="deal-title mb-4">
                                    <p><strong v-html="deal.blurb"></strong></p>
                                </div>
                                <!-- <a @click="updateModal(deal)" href="javascript:;" data-bs-toggle="modal" data-bs-target="#generalPop" class="btn btn-blue btn-no-fill circle-border">Learn More <i class="lni lni-chevron-right"></i></a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
export default {
    name:'TrackRecord',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
    },
    data(){ 
        return{
            asseturl:process.env.VUE_APP_ASSETURL,
            deals: {}
        }
    },
    methods: {
        updateModal(deal){
            this.store.state.generalModal={
                title: '',
                content: '',
                component: 'DealModal',
                prop:deal
            }
        }
    },
    computed: {},
    updated: function(){       
    },
    beforeMount: function(){
        fetch(process.env.VUE_APP_APIURL+"data/deals-trackrecord.json")
            .then(response => response.json())
            .then(data => (this.deals = data));
    }
}
</script>

<style scoped>
.tiled-deals .single-slide { border:1px solid #212167; border-radius: 10px; overflow:hidden;}
.tiled-deals .single-slide img { max-width:100%; }
.tiled-deals .single-slide .slide-content { padding: 20px;}
.tiled-deals .single-slide.has-button { padding-bottom: 60px; position: relative;}
.tiled-deals .single-slide.has-button .btn { bottom: 20px; position:absolute;}
</style>