<template>
  <div class="page">
    <Menu/>  
    <router-view :key="$route.fullPath"></router-view>
    <FooterSection/>
  </div>
  <MobileMenu/>  
  <GeneralModal/>
</template>

<script>
import store from '@/store'
import Menu from './components/Menu.vue'
import MobileMenu from './components/MobileMenu.vue'
import FooterSection from './components/FooterSection.vue'
import GeneralModal from './components/utilities/GeneralModal.vue'
export default {
  name: 'App',
  provide() {
    return {
      store: store
    }
  },
  components: {
    Menu,
    MobileMenu,
    FooterSection,
    GeneralModal
  },
  created(){}
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arsenal:wght@700&display=swap');
@import './assets/css/lineicons.min.css';
</style>
