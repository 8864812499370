import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/home/HomePage.vue'
import ContactPage from '../components/pages/ContactPage.vue'
import AboutPage from '../components/pages/AboutPage.vue'
import OPA from '../components/pages/OPA.vue'
import TeamPage from '../components/pages/TeamPage.vue'
import LeadershipPage from '../components/pages/LeadershipPage.vue'
import Values from '../components/pages/Values.vue'
import Services from '../components/pages/Services.vue'
import OurServices from '../components/pages/OurServices.vue'
import TrackRecord from '../components/pages/TrackRecord.vue'
import Careers from '../components/pages/Careers.vue'
import EP from '../components/pages/careers/EP.vue'
import MAP from '../components/pages/careers/MAP.vue'
import Interships from '../components/pages/careers/Internships.vue'
// import CareerPost from '../components/pages/CareerPost.vue'
import NewsPage from '../components/pages/NewsPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  { 
    path: "/contact", 
    name: 'Contact',
    component: ContactPage 
  },
  { 
    path: "/about-us", 
    name: 'About',
    component: AboutPage 
  },
  { 
    path: "/our-team", 
    name: 'Team',
    component: TeamPage 
  },
  { 
    path: "/our-partners-and-affiliates", 
    name: 'OPA',
    component: OPA 
  },
  { 
    path: "/our-founders", 
    name: 'Founders',
    component: LeadershipPage 
  },
  { 
    path: "/our-values", 
    name: 'Values',
    component: Values 
  },
  { 
    path: "/services", 
    name: 'Services',
    component: OurServices 
  },
  { 
    path: "/service/:slug", 
    name: 'Service',
    component: Services 
  },
  { 
    path: "/track-record", 
    name: 'TrackRecord',
    component: TrackRecord 
  },
  { 
    path: "/news", 
    name: 'News',
    component: NewsPage 
  },
  { 
    path: "/careers", 
    name: 'Careers',
    component: Careers 
  },
  // { 
  //   path: "/career/:slug", 
  //   name: 'Career',
  //   component: CareerPost
  // },
  { 
    path: "/career/experienced-professionals", 
    name: 'Career-EP',
    component: EP 
  },
  { 
    path: "/career/management-associate-programme", 
    name: 'Career-MAP',
    component: MAP 
  },
  { 
    path: "/career/internships", 
    name: 'Career-Internships',
    component: Interships 
  },
  { 
    path: "/:pathMatch(.*)*", 
    name: 'Home',
    component: HomePage 
  },
  { 
    path: "/:pathMatch(.*)*", 
    name: '#',
    component: HomePage 
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () { //scrollBehavior (to, from, savedPosition) {
    // return desired position
    // if (to.hash) {
    //   return {
    //     el: to.hash,
    //     behavior: 'smooth',
    //   }
    // }
    // return { 
    //   // el: '#main',
    //   top:0,
    //   behavior: 'smooth',
    // }
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  }
})

export default router
