<template>
    <!-- <PageHead :image="require('@/assets/images/pages/About-Us.jpg')"/> -->
    <PageHead image="/images/pages/Our-Values.jpg" title="Our Values"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Who We Are" :items="this.store.methods.getSingleMenuItem('About')"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                <!-- <h3 class="page-title">Values</h3> -->
                <div class="user_content">
                    <div class="row">
                        <div class="col-md-4 mb-4">
                            <div class="text-center">
                                <img class="mb-3" style="max-width: 100px;" src="/images/values/thumbicon.png" alt="" />
                                <h4>Integrity</h4>
                                <p>Personal and professional integrity in word and action is essential to what we offer.</p>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="text-center">
                                <img class="mb-3" style="max-width: 100px;" src="/images/values/handsicon.png" alt="" />
                                <h4>Partnership</h4>
                                <p>A desire to work with gifted entrepreneurs who share our beliefs that team work brings a more balanced results than individuality.</p>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="text-center">
                                <img class="mb-3" style="max-width: 100px;" src="/images/values/clockicon.png" alt="" />
                                <h4>Time & Effort</h4>
                                <p>Investing time and creative effort to understand and aid business enterprises adds more value than just bringing capital into play.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../utilities/PageHead.vue'
import SidebarTitle from '../SidebarTitle.vue'
export default {
    name:'Values',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle
    },
    props: {},
    data(){ 
        return{
            
        }
    },
    methods: {},
    computed: {},
    mounted: function(){
    },
    created: function(){
        
    }
}
</script>

<style scoped>
</style>