<template>
    <!-- <PageHead :image="require('@/assets/images/pages/internship.jpg')"/> -->
    <PageHead image="/images/pages/internship.jpg" title="Internships"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Careers" :items="this.store.methods.getSingleMenuItem('Careers')"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                <!-- <h3 class="page-title">Internships</h3> -->
                <div class="user_content">
                    <div v-if="page_data!=null">
                        <div v-if="(page_data instanceof Object) && page_data.content" v-html="page_data.content"></div>
                    </div>                    
                </div>
            </div>
        </div>
        <!-- Contact Form -->
        <div v-if="false" class="row mt-5 pt-4">
            <SidebarTitle title="Application Form" />
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                <CareerForm form="Internships"/>
            </div>
        </div>
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../../utilities/PageHead.vue'
import CareerForm from '../../utilities/CareerForm.vue'
import SidebarTitle from '../../SidebarTitle.vue'
export default {
    name:'Internships',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle,
        CareerForm
    },
    props: {},
    data(){ 
        return{
            
        }
    },
    methods: {},
    computed: {
        page_data(){
            return this.store.methods.getSinglePage('internships')
        }
    },
    mounted: function(){},
    created: function(){}
}
</script>

<style scoped>
</style>