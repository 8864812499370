<template>
    <PageHead v-if="serviceImage" :image="serviceImage" :title="serviceName"/>
    <div class="container page-section my-5 py-4">
        <div class="row">
            <SidebarTitle title="Services" :items="this.store.methods.getSingleMenuItem('Services')"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">                
                <!-- <h3 class="page-title" v-if="(service_data instanceof Object)">{{service_data.name}}</h3> -->
                <div class="user_content" v-if="service_data!=null">
                    <div v-if="(service_data instanceof Object) && service_data.content_above" v-html="service_data.content_above"></div>
                    <div v-if="deals.length">
                        <RecentDeals :deals="deals" />
                    </div>
                    <div v-if="sponsoring.length">
                        <Sponsoring :deals="sponsoring" />
                    </div>
                    <div v-if="(service_data instanceof Object) && service_data.content_below" v-html="service_data.content_below"></div>
                    <div v-if="deals2.length">
                        <RecentDeals :deals="deals2" />
                    </div>
                    <div v-if="(service_data instanceof Object) && service_data.content_3" v-html="service_data.content_3"></div>
                    <div class="mt-3">
                        <router-link :to="{ path: '/contact' }" class="btn btn-no-fill btn-khaki">Connect with Us</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../utilities/PageHead.vue'
import SidebarTitle from '../SidebarTitle.vue'
import RecentDeals from '../utilities/RecentDeals.vue'
import Sponsoring from '../utilities/Sponsoring.vue'
export default {
    name:'Services',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle,
        RecentDeals,
        Sponsoring
    },
    props: {},
    data(){ 
        return{
            service_slug: this.$route.params.slug,
            deals:[],
            deals2:[],
            sponsoring:[]
        }
    },
    methods: {
    },
    computed: {
        service_data(){
            return this.store.methods.getSingleService(this.service_slug)
        },
        serviceImage(){
            let serviceData=this.store.methods.getSingleService(this.service_slug);
            if(serviceData!=null){
                return serviceData.image
            }
            return null
        },
        serviceName(){
            let serviceData=this.store.methods.getSingleService(this.service_slug);
            if(serviceData!=null){
                return serviceData.name
            }
            return null
        }
    },
    beforeMount: function(){
        fetch(process.env.VUE_APP_APIURL+"deal/"+this.service_slug)
            .then(response => response.json())
            .then(data => (this.deals = data));
        if(this.service_slug=='corporate-finance'){
            fetch(process.env.VUE_APP_APIURL+"deal/cf2")
            .then(response => response.json())
            .then(data => (this.deals2 = data));
        }
        if(this.service_slug=='continuing-sponsorship'){
            fetch(process.env.VUE_APP_APIURL+"sponsor-list")
            .then(response => response.json())
            .then(data => (this.sponsoring = data));
        }
    },
    created: function(){
        this.store.methods.getServices();
    }
}
</script>

<style scoped>
</style>