<template>
    <footer class="text-white">
        <div class="container pt-5 mb-3">
            <div class="row">
                <div class="col-sm-6 col-md-3" v-for="(items, index) in this.store.state.footermenu" :key="index">
                    <div class="footer-container mb-4" v-for="(item,j) in items" :key="j">
                        <a class="d-inline-block me-3 mb-1"><strong><router-link :to="{ name: item.url}">{{ item.name }}</router-link></strong></a>
                        <a v-if="(item.url=='Contact')" href="https://www.linkedin.com/company/primepartners-corporate-finance" target="_blank" >
                            <h5 class="d-inline"><i class="lni lni-linkedin-original"></i></h5>
                        </a>
                        <ul v-if="item.child">
                            <li class="mb-1" v-for="(sub_item, i) in item.child" :key="i">
                                <router-link v-if="sub_item.param" :to="{ name: sub_item.url, params: { slug: sub_item.param }}">{{ sub_item.name }}</router-link>                                
                                <router-link v-else :to="{ name: sub_item.url}">{{ sub_item.name }}</router-link>                                
                                <!-- <a class="" :href="sub_item.url">{{ sub_item.name }}</a> -->
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom"></div>
        <div class="container py-4">
            <div class="row">
                <div class="col-12 col-lg-6 text-center text-lg-start">
                    <p class="m-0"><small>16 Collyer Quay #10-00 Income at Raffles Singapore 049318</small></p>
                </div>
                <div class="col-12 col-lg-6 text-center text-lg-end">
                    <p class="m-0"><small>Copyright © 2021 PrimePartners. All rights reserved.</small></p>
                </div>
                <a @click="updateModal('/resources/PPCF - Privacy Policy 2022.pdf#toolbar=0')" href="javascript:;" data-bs-toggle="modal" data-bs-target="#generalPop"><small>Privacy Policy</small></a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name:'FooterSection',
    inject:[
        'store'
    ],
    components: {},
    props: {},
    data(){ 
        return{}
    },
    methods: {
        updateModal(deal){
            // console.log(deal);
            this.store.state.generalModal={
                title: '',
                content: '',
                component: 'IframeModal',
                prop:deal
            }
        }
    },
    computed: {}
}
</script>

<style scoped>
footer { background: #888888; }
.footer-bottom { border-top: 1px solid #fff; }
</style>