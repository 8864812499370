<template>
    <div class="team-tile mb-4">
        <a @click="updateModal" href="javascript:;" data-bs-toggle="modal" data-bs-target="#generalPop">
            <img :src="item.image" alt="" />
            <div class="team-tile-content">
                <p class="h6 mb-1"><strong>{{item.name}}</strong></p>
                <p class="mb-0">{{item.post}}</p>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name:'TeamTile',
    inject:[
        'store'
    ],
    components: {
    },
    props: {
        item:Object
    },
    data(){
        return{}
    },
    methods: {
        updateModal(){
            this.store.state.generalModal={
                title: '',
                content: '',
                component: 'TeamModal',
                prop:this.item
            }
        }
    },
    computed: {},
    mounted: function(){
    }
}
</script>

<style scoped>
</style>