<template>
    <!-- <PageHead :image="require('@/assets/images/pages/About-Us.jpg')"/> -->
    <PageHead image="/images/pages/About-Us.jpg" title="About Us"/>
    <div class="container page-section my-5 py-4">
        <div class="row wow fadeInUp">
            <SidebarTitle title="Who We Are" :items="this.store.methods.getSingleMenuItem('About')"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp user_content" data-wow-delay="500ms">
                <div v-if="page_data!=null">
                    <div v-if="(page_data instanceof Object) && page_data.content" v-html="page_data.content"></div>
                </div>
            </div>
        </div>

        <!-- <div class="row mt-5 pt-4">
            <SidebarTitle title="Who We Are"/>
            <div class="col-sm-8 col-md-9 wow fadeInUp" data-wow-delay="500ms">
                
            </div>
        </div> -->
    </div>
    <RecentDeals/>
</template>

<script>
import PageHead from '../utilities/PageHead.vue'
import SidebarTitle from '../SidebarTitle.vue'
export default {
    name:'HomePage',
    inject:[
        'store'
    ],
    components: {
        PageHead,
        SidebarTitle
    },
    props: {},
    data(){ 
        return{
            aboutitems: [{ "name": "Hiren" }]
        }
    },
    methods: {},
    computed: {
        page_data(){
            return this.store.methods.getSinglePage('about-us')
        }
    },
    mounted: function(){
    }
}
</script>

<style scoped>
</style>