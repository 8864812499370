<template>
    <div class="mobile-menu">
        <nav id="mobile-menu">
            <ul >
                <li v-for="(item, index) in this.store.state.menuitems" :key="index">
                    <!-- <a class="" :href="item.url">{{ item.name }}</a>  -->
                    <router-link :to="{ name: item.url}" custom v-slot="{ navigate, href }">
                        <a :href="href" @click="[navigate, closeMmenu]">{{ item.name }}</a>
                    </router-link>

                    <ul v-if="item.child">
                        <li v-for="(sub_item, i) in item.child" :key="i">
                            <!-- <a class="" :href="sub_item.url">{{ sub_item.name }}</a> -->
                            <router-link v-if="sub_item.param" :to="{ name: sub_item.url, params: { slug: sub_item.param }}" custom v-slot="{ navigate, href }">
                                <a :href="href" @click="[navigate, closeMmenu]">{{ sub_item.name }}</a>
                            </router-link>
                            <router-link v-else :to="{ name: sub_item.url}" custom v-slot="{ navigate, href }">
                                <a :href="href" @click="[navigate, closeMmenu]">{{ sub_item.name }}</a>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
var mmenu_instance=null;
import 'mmenu-js/dist/mmenu.css'
import 'mmenu-js/dist/mmenu.js'
import Mmenu from 'mmenu-js'
export default {
    name:'MobileMenu',
    inject:[
        'store'
    ],
    components: {},
    props: {},
    data() {
        return{}
    },
    methods: {
        closeMmenu(){
            // console.log('clicked');
            // document.getElementById('mobile-menu').classList.remove('mm-menu_opened');

            const api = mmenu_instance.API;
            const panel = document.querySelector( "#mobile-menu" );
            api.close( panel );
        }
    },
    computed: {},
    created: function() {
        //this.store.methods.getmenuitems();       
    },
    updated: function(){
        mmenu_instance=new Mmenu( "#mobile-menu", {
            "slidingSubmenus":false,
            "extensions": [
                "position-right",
                "pagedim-black"
            ]
        });
    }
}
</script>

<style scoped>
</style>