<template>
    <form class="form" method="post" @submit="careerSubmit">
        <div class="row">
            <div class="col-md-6">
                <input type="text" v-model="cf_fname" class="form-control mb-3" name="fname" placeholder="First Name*" required />
            </div>
            <div class="col-md-6">
                <input type="text" v-model="cf_lname" class="form-control mb-3" name="lname" placeholder="Last Name*" required />
            </div>
            <div class="col-md-6">
                <input type="email" v-model="cf_email" class="form-control mb-3" name="email" placeholder="Email*" required />
            </div>
            <div class="col-md-6">
                <input type="text" v-model="cf_dob" class="form-control mb-3" name="dob" placeholder="DOB*" required />
            </div>
            <div class="col-md-6">
                <input type="text" v-model="cf_number" class="form-control mb-3" name="number" placeholder="Contact Number" />
            </div>
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <p class="mb-1">Upload Your CV*</p>
                <input type="file" class="form-control mb-3" name="file" placeholder="Upload Your CV*" @change="handleFileUpload( $event )" required />
            </div>
        </div>
        <button type="submit" class="btn btn-blue btn-no-fill">Submit</button>
        <span class="ms-2">{{form_submit_info}}</span>
    </form>
</template>

<script>
export default {
    name:'CareerForm',
    components: {
    },
    props: {
        form: String,
    },
    data(){ 
        return{
            cf_fname:'',
            cf_lname:'',
            cf_email:'',
            cf_dob:'',
            cf_number:'',
            cf_file:'',
            form_submit_info:''
        }
    },
    methods: {
        handleFileUpload( event ){
            this.cf_file = event.target.files[0];
            // console.log(this.cf_file);
        },
        careerSubmit(e){
            this.form_submit_info="Submitting your request";
            e.preventDefault();
            // var form = document.getElementById('form');
            // console.log(e.target);
            var formData = new FormData(e.target);
            formData.append('file', this.file);
            formData.append('form', this.form);
            // console.log(formData.get('fname'));
            fetch(process.env.VUE_APP_SITEURL+"php/sendmail.php",{
                method:'POST',
                // headers: new Headers({
                //     // 'Content-Type': 'application/json',
                //     // 'Accept': 'application/json',
                //     'Content-Type': 'multipart/form-data'
                // }),
                body: formData
                // JSON.stringify({
                //     form: this.form,
                //     fname: this.cf_fname,
                //     lname: this.cf_lname,
                //     email: this.cf_email,
                //     dob: this.cf_dob,
                //     number: this.cf_number,
                //     file: this.cf_file
                // })
            })
            .then(response => response.json())
            .then(data => {
                if(data.msg==1){
                    this.form_submit_info="Sent!";
                }
            });
        }
    },
    computed: {}
}
</script>

<style scoped>
</style>